import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { Layout, Meta } from '../components'

const Title = styled.h1`
  color: var(--highlight);
`

const ProjectList = styled.ul`
  padding-left: 1em;
`

interface IndexProps {
  data: GatsbyTypes.IndexQuery
}
const Index: React.FC<IndexProps> = ({ data }) => {
  const projects = data.allProjectsJson.nodes

  return (
    <Layout>
      <Meta title="Welcome" />
      <Row>
        <Col>
          <Title>Welcome</Title>
          <p>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
            magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
            gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing
            elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos
            et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor
            sit amet.
          </p>

          <p>
            <Button href="/download/example.pdf" variant="primary">
              Some example download
            </Button>
          </p>

          <StaticImage src="../images/presentation.jpg" alt="Shrek Presntation" placeholder="blurred" />
        </Col>
        <Col md={3}>
          <h2 className="h3">Projects</h2>
          <ProjectList>
            {projects.map(project => (
              <li key={project.fields?.slug}>
                {project.name} ({project.leader})
              </li>
            ))}
          </ProjectList>
        </Col>
      </Row>
    </Layout>
  )
}
export default Index

export const query = graphql`
  query Index {
    allProjectsJson {
      nodes {
        name
        leader
        fields {
          slug
        }
      }
    }
  }
`
